
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, ref } from 'vue';
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook';
import metricsApi from 'src/apis/masters/metrics';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { ensureUserRefreshAndMasters } from 'src/hooks/masterHook';
import { ERROR_GROUP_SYSTEM, ERROR_REASON_IN_USE, ERROR_REASON_MAX_PROCESSING_JOBS, type ErrorGroup } from 'src/consts';
import { FgInput } from 'src/components/UIComponents';
import MetricsSearchPanel, {
  SearchStateWithIncludeDisabled as MetricsSearchPanelSearchState,
} from 'src/components/MetricsSearchPanel/index.vue';
import DirectInputMetricsFormModal from 'src/views/Dashboard/Settings/Metrics/components/DirectInputMetricsFormModal/index.vue';
import SummaryMetricsFormModal from 'src/views/Dashboard/Settings/Metrics/components/SummaryMetricsFormModal/index.vue';
import CalculatedMetricsFormModal from 'src/views/Dashboard/Settings/Metrics/components/CalculatedMetricsFormModal/index.vue';
import ReferenceMetricsFormModal from 'src/views/Dashboard/Settings/Metrics/components/ReferenceMetricsFormModal/index.vue';
import BundledMetricsFormModal from 'src/views/Dashboard/Settings/Metrics/components/BundledMetricsFormModal/index.vue';
import LogiSystemDataMetricsFormModal from 'src/views/Dashboard/Settings/Metrics/components/LogiSystemDataMetricsFormModal/index.vue';
import AccessDeniedMessageCard from 'src/components/AccessDeniedMessageCard.vue';
import MetricsList, { type ExtendedMetrics } from 'src/views/Dashboard/Settings/Metrics/components/MetricsList.vue';
import MetricsTypeSelectModal from 'src/views/Dashboard/Settings/Metrics/components/MetricsTypeSelectModal.vue';
import type { Metrics } from 'src/models/new/metrics';
import { isMetricsReferenceMetrics } from 'src/models/new/Metrics/referenceMetrics';
import { MetricsIndexRequestParameters } from 'src/models/api/Metrics/metricsIndexRequestParameters';
import { isMetricsDirectInputMetrics } from 'src/models/new/Metrics/BasicMetrics/directInputMetrics';
import { isMetricsLogimeterDataMetrics } from 'src/models/new/Metrics/BasicMetrics/LogiSystemDataMetrics/logimeterDataMetrics';
import { isMetricsBundledMetrics } from 'src/models/new/Metrics/bundledMetrics';
import { isMetricsCalculatedMetrics } from 'src/models/new/Metrics/calculatedMetrics';
import { isMetricsSummaryMetrics } from 'src/models/new/Metrics/summaryMetrics';
import { TIME_SPAN_MONTHLY } from 'src/business/timeSpan';
import { useWorkplacesProvider } from 'src/composables/asyncResources/useWorkplaces';
import { useMetricsAccessGroupsOfMetricsContext } from './composables/useMetricsAccessGroupsOfMetrics';
import { useLogiCoredataWorkplacesProvider } from 'src/composables/asyncResources/useLogiCoredataWorkplaces';
import { useLogiCoredataBudgetGroupsProvider } from 'src/composables/asyncResources/useLogiCoredataBudgetGroups';
import { useDataSourcesProvider } from 'src/composables/asyncResources/useDataSources';
import { usePaginationContainer } from 'src/components/UIComponents/PaginationContainer.vue';

const METRICS_MESSAGE_IMPORT_CSV = 'CSVデータ入力を開始しました';
const METRICS_MESSAGE_EXPORT_CSV = 'CSVデータ出力を開始しました';
const ITEM_PER_PAGE = 50;

type DeleteCandidate = Partial<Metrics>;

interface State {
  pageName: string | null;
  userId: number | null;
  msgVars: Record<string, string>;
  opType: string | null;
  hasMetricsGtManagerRole: boolean;

  isRequestAccessDenied: boolean;
  metricsItems: Metrics[];
  extendedMetricsItems: ExtendedMetrics[];
  hasList: boolean;
  dataLoadState: { availableTotal: number; loadedSlices: number };

  searchParams: MetricsSearchPanelSearchState;
  // 検索実行時のsearchParams保持用に定義
  lastSearchParams: MetricsSearchPanelSearchState;

  targetMetrics: Metrics | null;
  deleteCandidate: DeleteCandidate | null;
  showMetricsTypeSelectModal: boolean;
  showDirectInputMetricsFormModal: boolean;
  showLogiSystemDataMetricsFormModal: boolean;
  showCalculatedMetricsFormModal: boolean;
  showSummaryMetricsFormModal: boolean;
  showBundledMetricsFormModal: boolean;
  showReferenceMetricsFormModal: boolean;
  showDeleteModal: boolean;
}

const initialSearchParams = {
  name: null,
  workplaceId: null,
  timeSpan: null,
  metricsType: null,
  includeDisabled: false,
};

export default defineComponent({
  components: {
    AccessDeniedMessageCard,
    MetricsSearchPanel,
    DirectInputMetricsFormModal,
    LogiSystemDataMetricsFormModal,
    CalculatedMetricsFormModal,
    SummaryMetricsFormModal,
    ReferenceMetricsFormModal,
    BundledMetricsFormModal,
    MetricsList,
    MetricsTypeSelectModal,
    PaginationContainer: usePaginationContainer<ExtendedMetrics>(),
  },
  setup() {
    const root = getCurrentInstance()!.proxy;
    setPageName(root, 'メトリクス設定');
    const state: State = reactive({
      ...wrappedMapGetters(root.$store, 'displayPageName', ['pageName']),
      userId: wrappedMapGetters(root.$store, 'user', ['id']).id,
      msgVars: { create: '作成', update: '編集', delete: '削除' },
      opType: 'create',
      hasMetricsGtManagerRole: computed(() => root.$store.getters['user/hasMetricsGtManagerRole']),

      isRequestAccessDenied: false,
      metricsItems: [],
      extendedMetricsItems: computed(() => {
        if (workplacesRef.value.length === 0) {
          return [];
        }
        return state.metricsItems.map((metrics) => {
          return {
            ...metrics,
            workplaceName: workplacesRef.value.find((el) => el.id === metrics.workplaceId)?.name || '',
          };
        });
      }),
      hasList: computed(() => state.extendedMetricsItems.length > 0),
      dataLoadState: { availableTotal: 0, loadedSlices: 0 },

      searchParams: { ...initialSearchParams },
      lastSearchParams: { ...initialSearchParams },

      targetMetrics: null,
      deleteCandidate: null,
      showMetricsTypeSelectModal: false,
      showSaveModalTypeSelection: false,
      showDirectInputMetricsFormModal: false,
      showLogiSystemDataMetricsFormModal: false,
      showCalculatedMetricsFormModal: false,
      showSummaryMetricsFormModal: false,
      showBundledMetricsFormModal: false,
      showReferenceMetricsFormModal: false,
      showDeleteModal: false,
    });
    const refCsvImport = ref<InstanceType<typeof FgInput>>();

    const { workplacesRef } = useWorkplacesProvider();
    useMetricsAccessGroupsOfMetricsContext();
    useLogiCoredataWorkplacesProvider();
    useLogiCoredataBudgetGroupsProvider();
    useDataSourcesProvider();

    const isSearchParamsEmpty = (searchParams: MetricsSearchPanelSearchState): boolean => {
      return searchParams.workplaceId === null && searchParams.metricsType === null && searchParams.timeSpan === null;
    };

    const buildMetricsIndexQueryParams = (
      searchParams: MetricsSearchPanelSearchState,
      page: number | null,
    ): MetricsIndexRequestParameters => {
      const queryParams = {
        page: page,
        name: searchParams.name,
        logiscope_workplace_id: searchParams.workplaceId,
        time_spans: null,
        metrics_types: null,
        include_disabled: searchParams.includeDisabled,
      };
      if (searchParams.timeSpan) {
        Object.assign(queryParams, { time_spans: [searchParams.timeSpan] });
      }
      if (searchParams.metricsType) {
        Object.assign(queryParams, { metrics_types: [searchParams.metricsType] });
      }

      return queryParams;
    };

    const loadMetrics = async (searchParams: MetricsSearchPanelSearchState): Promise<void> => {
      if (isSearchParamsEmpty(searchParams)) {
        return;
      }

      state.dataLoadState.loadedSlices = 1;
      const queryParams = buildMetricsIndexQueryParams(searchParams, 1);
      try {
        const response = await metricsApi.index(queryParams);
        state.metricsItems = response.result;
        state.dataLoadState.availableTotal = response.pagination.total;
        state.isRequestAccessDenied = false;
      } catch (err: any) {
        const errStatus = err.response.status;
        if (errStatus === 403) {
          state.isRequestAccessDenied = true;
        }
      }
    };

    const reloadMetrics = async (): Promise<void> => {
      await loadMetrics(state.lastSearchParams);
    };

    // 追加ロード
    const loadMetricsNextSlice = async (): Promise<void> => {
      state.dataLoadState.loadedSlices += 1;
      const queryParams = buildMetricsIndexQueryParams(state.lastSearchParams, state.dataLoadState.loadedSlices);
      try {
        const response = await metricsApi.index(queryParams);
        state.dataLoadState.availableTotal = response.pagination.total;
        state.metricsItems = [...state.metricsItems, ...response.result];
        state.isRequestAccessDenied = false;
      } catch (err: any) {
        const errStatus = err.response.status;
        if (errStatus === 403) {
          state.isRequestAccessDenied = true;
        }
      }
    };

    const onSearch = async (): Promise<void> => {
      state.lastSearchParams = { ...state.searchParams };
      await loadMetrics(state.searchParams);
    };

    const createWithDefaultCandidate = (skeletonMetrics: Metrics): void => {
      if (isMetricsLogimeterDataMetrics(skeletonMetrics)) {
        openLogiSystemDataMetricsFormModal();
      } else if (isMetricsDirectInputMetrics(skeletonMetrics)) {
        openDirectInputMetricsFormModal();
      } else if (isMetricsCalculatedMetrics(skeletonMetrics)) {
        openCalculatedMetricsFormModal();
      } else if (isMetricsSummaryMetrics(skeletonMetrics)) {
        skeletonMetrics.timeSpan = TIME_SPAN_MONTHLY;
        openSummaryMetricsFormModal();
      } else if (isMetricsBundledMetrics(skeletonMetrics)) {
        openBundledMetricsFormModal();
      } else if (isMetricsReferenceMetrics(skeletonMetrics)) {
        openReferenceMetricsFormModal();
      }
      state.targetMetrics = reactive(skeletonMetrics);

      closeCreateSkeletonModal();
    };

    const openFileDialog = (): void => {
      (refCsvImport.value!.$el.children[0] as HTMLButtonElement).click();
    };

    const uploadCsv = async (e: any): Promise<void> => {
      const file = e.target.files[0];
      if (!file) {
        return;
      }

      try {
        const data = new FormData();
        data.append('csv', file);
        await metricsApi.importCsv(data);
        notifySuccess1(root, METRICS_MESSAGE_IMPORT_CSV);
      } catch (err: any) {
        const errStatus: number = err.response?.status;
        const errRes = err.response?.data || {};
        if (errStatus === 400 && errRes.reason === ERROR_REASON_MAX_PROCESSING_JOBS) {
          const msg = 'お客様の一括処理のリクエスト制限数を超えています。しばらくしてから再度実施ください。';
          notifyError1(root, msg, { err });
        } else {
          notifyError1(root, 'メトリクスCSVのアップロードに失敗しました。', { err });
        }
      }
    };

    const downloadCsv = async (): Promise<void> => {
      const queryParams = buildMetricsIndexQueryParams(state.lastSearchParams, null);
      try {
        await metricsApi.exportCsv(queryParams);
        notifySuccess1(root, METRICS_MESSAGE_EXPORT_CSV);
      } catch (err: any) {
        const errStatus: number = err.response?.status;
        const errRes = err.response?.data || {};
        if (errStatus === 400 && errRes.reason === ERROR_REASON_MAX_PROCESSING_JOBS) {
          const msg = 'お客様の一括処理のリクエスト制限数を超えています。しばらくしてから再度実施ください。';
          notifyError1(root, msg, { err });
        } else {
          notifyError1(root, 'メトリクスCSVの作成に失敗しました。', { err });
        }
      }
    };

    const openCreateSkeletonModal = (): void => {
      state.showMetricsTypeSelectModal = true;
    };

    const editSelectedMetrics = async (metrics: Metrics): Promise<void> => {
      state.targetMetrics = reactive(structuredClone(metrics));
      if (isMetricsLogimeterDataMetrics(metrics)) {
        openLogiSystemDataMetricsFormModal();
      } else if (isMetricsDirectInputMetrics(metrics)) {
        openDirectInputMetricsFormModal();
      } else if (isMetricsCalculatedMetrics(metrics)) {
        openCalculatedMetricsFormModal();
      } else if (isMetricsSummaryMetrics(metrics)) {
        openSummaryMetricsFormModal();
      } else if (isMetricsReferenceMetrics(metrics)) {
        openReferenceMetricsFormModal();
      } else if (isMetricsBundledMetrics(metrics)) {
        openBundledMetricsFormModal();
      }
    };

    const openDirectInputMetricsFormModal = (): void => {
      state.showDirectInputMetricsFormModal = true;
    };
    const openLogiSystemDataMetricsFormModal = (): void => {
      state.showLogiSystemDataMetricsFormModal = true;
    };
    const openCalculatedMetricsFormModal = (): void => {
      state.showCalculatedMetricsFormModal = true;
    };
    const openSummaryMetricsFormModal = (): void => {
      state.showSummaryMetricsFormModal = true;
    };
    const openBundledMetricsFormModal = (): void => {
      state.showBundledMetricsFormModal = true;
    };
    const openReferenceMetricsFormModal = (): void => {
      state.showReferenceMetricsFormModal = true;
    };

    const closeSaveModal = (): void => {
      state.targetMetrics = null;
      state.showDirectInputMetricsFormModal = false;
      state.showLogiSystemDataMetricsFormModal = false;
      state.showCalculatedMetricsFormModal = false;
      state.showSummaryMetricsFormModal = false;
      state.showBundledMetricsFormModal = false;
      state.showReferenceMetricsFormModal = false;
    };

    const closeCreateSkeletonModal = (): void => {
      state.showMetricsTypeSelectModal = false;
    };

    const openDeleteModal = (item: DeleteCandidate): void => {
      state.deleteCandidate = item;
      state.showDeleteModal = true;
    };

    const closeDeleteModal = (): void => {
      state.showDeleteModal = false;
    };

    const deleteItem = async (): Promise<void> => {
      state.opType = 'delete';
      try {
        await metricsApi.delete(state.deleteCandidate?.id!);
        await reloadMetrics();
        closeDeleteModal();
        notifySuccess1(root, `${state.pageName}を${state.msgVars[state.opType]}しました`);
      } catch (err: any) {
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if ([403, 404].includes(errStatus)) {
          notifyError1(root, 'アクセスする権限がありません。管理者にお問合せください。', { err });
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_IN_USE) {
          const msg = 'すでに使われているマスタです。削除できません。' + '無効化をおすすめします。';
          notifyError1(root, msg, { timeout: 5 * 1000 });
        } else {
          const errId = 'ERR00003';
          const msg =
            `${state.pageName}の${state.msgVars[state.opType]}に失敗しました。管理者に連絡してください。` +
            `(ERR: ${state.pageName} ${errId}, user_id:${state.userId})`;
          notifyError1(root, msg, { err });
        }
      }
    };

    const onUpdated = async (message: string): Promise<void> => {
      await reloadMetrics();
      closeSaveModal();
      notifySuccess1(root, message);
    };

    // TODO: アプリケーションを通じて要統一、reportValue/Detailとエラーフォーマットが微妙に異なる
    const reportError = async (errorGroup: ErrorGroup, message: string, error: any, errorId: string) => {
      const formattedMessage =
        errorGroup === ERROR_GROUP_SYSTEM
          ? `(ERR: ${state.pageName} ${errorId ? ` ${errorId}` : ''}, user_id:${state.userId})`
          : message;

      notifyError1(root, formattedMessage, error);
    };

    onMounted(async () => {
      // ログインユーザー情報をAPIで再取得
      await ensureUserRefreshAndMasters(root);
    });

    return {
      state,
      openCreateSkeletonModal,
      closeCreateSkeletonModal,
      closeSaveModal,
      deleteItem,
      openDeleteModal,
      closeDeleteModal,
      onSearch,
      createWithDefaultCandidate,
      editSelectedMetrics,
      onUpdated,
      reportError,
      refCsvImport,
      openFileDialog,
      uploadCsv,
      downloadCsv,
      loadMetricsNextSlice,
      ITEM_PER_PAGE,
    };
  },
});
